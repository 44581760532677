<template>
  <v-app id="main">
    <router-view @authenticated="setAuthenticated" />
  </v-app>
  <!-- <div id="app"> -->
  <!-- <div id="nav"> -->
  <!-- <router-link v-if="authenticated" to="/login" v-on:click.native="logout()" replace>Logout</router-link> -->
  <!-- </div> -->
  <!-- <router-view @authenticated="setAuthenticated" /> -->
  <!-- </div> -->
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      authenticated: false,
      mockAccount: {
        username: "wessel.o",
        password: "password"
      }
    };
  },
  mounted() {
    // if(!this.authenticated) {
    if (!this.$session.exists()) {
      this.$router.replace({ name: "login" });
    }
  },
  methods: {
    setAuthenticated(status) {
      this.authenticated = status;
    },
    logout() {
      this.authenticated = false;
    }
  }
};
</script>
