<template>
  <div id="overview">
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <v-list-item @click="toOverview()">
          <v-list-item-action>
            <v-icon>dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Overzicht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-icon>person</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Mijn account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$session.get('admin') == 1" @click="toUsers()">
          <v-list-item-action>
            <v-icon>people</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Gebruikers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="toContact()">
          <v-list-item-action>
            <v-icon>email</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Uitloggen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img
        src="@/assets/Logo-Teneo-500x200-transparant.png"
        alt="Teneo"
        max-height="100%"
        max-width="200px"
      ></v-img>
    </v-app-bar>

    <v-content>
      <v-container fluid fill-height>
        <v-layout
          align-center
          justify-center
          text-xs-center
          style="background-color: white; padding:10px;"
          class="elevation-3"
        >
          <div text-xs-center style="width:100%">
            <h3>Mijn account</h3>
            <v-alert
                  :value="unknownErrorAlert"
                  type="error"
                  transition="fade-transition"
                  dismissible
                  >Er deed zich een onverwachte fout voor. Probeer het later
                  opnieuw.</v-alert>
            <v-alert
            :value="updatedAlert"
            type="success"
            transition="fade-transition"
            dismissible
            >Accountsgegevens zijn bijgewerkt.</v-alert>
            <v-alert
                  :value="networkErrorAlert"
                  type="warning"
                  transition="fade-transition"
                  dismissible
                  >Er deed zich een netwerkfout voor. Probeer het later opnieuw.</v-alert>
            <v-form ref="accountForm" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field
                            v-model="editedUser.username"
                            :rules="euUsernameRules"
                            label="Gebruikersnaam"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field
                            v-model="editedUser.email"
                            :rules="euEmailRules"
                            label="Emailadres"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field
                            v-model="editedUser.password"
                            :rules="euPasswordRules"
                            error-count="2"
                            label="Nieuw wachtwoord"
                            type="password"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field
                            v-model="passwordRepeatInput"
                            :rules="passwordRepeatRules"
                            error-count="2"
                            label="Wachtwoord herhaling"
                            type="password"
                        ></v-text-field>
                    </v-col>
                    
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-btn :disabled="!valid" color="primary" @click="validate">Bijwerken</v-btn>
                        <p style="margin-top: 10px; margin-bottom: 0px;" class="caption">Wilt u uw account verwijderen? Klik dan <a @click="toDeleteUser()">hier</a>.</p>
                    </v-col>
                </v-row>
            </v-form>
          </div>
        </v-layout>
      </v-container>
    </v-content>

    <v-footer color="white" app>
      <span>&copy; Teneo IoT B.V. 2020</span>
    </v-footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      drawer: null,
      passwordRepeatInput: null,
      valid: false,
      unknownErrorAlert: false,
      networkErrorAlert: false,
      updatedAlert: false,
      editedUser: {
        id: 0,
        username: null,
        company: null,
        email: null,
        password: null,
        admin: 0
      },
      euUsernameRules: [value => !!value || "Gebruikersnaam is verplicht"],
      euEmailRules: [
        value => !!value || "Emailadres is verplicht",
        value => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value) || "Emailadres is ongeldig"
      ],
      euPasswordRules: [
        // value => (value && value.length >= 5) || "Wachtwoord moet uit ten minste 5 tekens bestaan",
      ],
      passwordRepeatRules: [
        value => (value == this.editedUser.password) || "De ingevulde wachtwoorden moeten overeen komen"
      ]
    };
  },
  beforeCreate: function() {
    if (!this.$session.exists() || this.$session.get("authToken") == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  mounted: function() {
    // Get devices on initial page load
    this.$nextTick(this.getUser());
  },
  methods: {
    toOverview: function() {
      this.$router.push("/overview");
    },
    toUsers: function() {
      this.$router.push("/users");
    },
    toDeleteUser: function() {
      this.$router.push("/delete/user")
    },
    toContact: function() {
      this.$router.push("/contact");
    },
    getUser: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      }

      // Perform POST request to get user data
      axios.post("https://gowessel.teneo-iot.nl/retrieve/user", params)
      .then(res => {
        if (res.status == 200) {
          // Disable network error
          this.networkErrorAlert = false;
          // Do something

          this.editedUser = res.data
        }
      })
      .catch(error => {
        if (!error.response) {
          // Network error
          this.networkErrorAlert = true;
        }
      })
    },
    updateUser: function() {
      // Perform POST request to update user
      axios.post("https://gowessel.teneo-iot.nl/update/user", this.editedUser)
      .then(res => {
        if (res.status == 200) {
          // Disable network error
          this.networkErrorAlert = false;
          // Show success message
          this.unknownErrorAlert = false;
          this.updatedAlert = true;
        }
      })
      .catch(error => {
        if (!error.response) {
          // Network error
          this.networkErrorAlert = true;
        } else if (error.response.status == 401) {
          // Handle 401
          this.updatedAlert = false;
          this.unknownErrorAlert = true;
        }
      })
    },
    logout: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      };

      // Perform POST request to log out
      axios
        .post("https://gowessel.teneo-iot.nl/logout", params)
        .then(res => {
          if (res.status == 200) {
            // Disable network error
            this.networkErrorAlert = false;
            // Destroy session and reroute to main page
            this.$session.destroy();
            this.$router.push("/");
          }
        })
        .catch(error => {
          if (!error.response) {
            // Network error
            this.networkErrorAlert = true;
          } else if (error.response.status == 404) {
            // Handle 404
            this.networkErrorAlert = true;
          }
        });
    },
    validate: function() {
      if (this.$refs.accountForm.validate()) {
        this.updateUser();
      }
    }
  }
};
</script>

<style scoped>
#secure {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 20px;
  margin-top: 10px;
}
</style>
