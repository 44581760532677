<template>
  <div id="overview">
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <v-list-item @click="printSomething()">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-action>
            <v-icon>account_box</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img
        src="@/assets/Logo-Teneo-500x200-transparant.png"
        alt="Teneo"
        max-height="100%"
        max-width="200px"
      ></v-img>
    </v-app-bar>

    <v-content>
      <v-container fluid fill-height>
        <v-layout
          align-center
          justify-center
          text-xs-center
          style="background-color: white; padding:10px"
          class="elevation-3"
        >
          <div text-xs-center style="width:100%">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="number"
                :rules="numberRules"
                label="Number"
                type="number"
                required
              ></v-text-field>
              <v-select
                v-model="type"
                :items="typeItems"
                :rules="[v => !!v || 'Type is verplicht']"
                label="Type"
                required
              ></v-select>
              <v-text-field
                v-model="appKey"
                :rules="appKeyRules"
                label="App Key"
                required
              ></v-text-field>
              <v-text-field
                v-model="appEui"
                :rules="appEuiRules"
                label="App EUI"
                required
              ></v-text-field>
              <v-text-field
                v-model="devEui"
                :rules="devEuiRules"
                label="Device EUI"
                required
              ></v-text-field>
              <v-text-field
                v-model="userId"
                :rules="userIdRules"
                label="Klant ID"
                type="number"
                required
              ></v-text-field>
              <v-btn :disabled="!valid" color="primary" @click="validate"
                >Toevoegen</v-btn
              >
            </v-form>
          </div>
        </v-layout>
      </v-container>
    </v-content>

    <v-footer color="white" app>
      <span>&copy; Teneo IoT B.V. 2020</span>
    </v-footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "adddevice",
  data() {
    return {
      drawer: null,
      valid: true,
      number: "",
      numberRules: [
        value => !!value || "Nummer is verplicht",
        value => (value && value > 0) || "Value moet groter dan 0 zijn"
      ],
      type: null,
      typeItems: ["Grondvocht", "Vulgraad", "Watermeter"],
      appKey: "",
      appKeyRules: [value => !!value || "App Key is verplicht"],
      appEui: "",
      appEuiRules: [value => !!value || "App EUI is verplicht"],
      devEui: "",
      devEuiRules: [value => !!value || "Device EUI is verplicht"],
      userId: "",
      userIdRules: [
        value => !!value || "Klant ID is verplicht",
        value => (value && value > 0) || "Gebruiker ID moet groter dan 0 zijn"
      ]
    };
  },
  beforeCreate: function() {
    if (!this.$session.exists() || this.$session.authToken == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  methods: {
    logout: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      };

      // Perform POST request to log out
      axios.post("https://gowessel.teneo-iot.nl/logout", params).then(res => {
        if (res.status == 200) {
          this.$session.destroy();
          this.$router.push("/");
        }
      });
    },
    insertDevice: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken"),
        number: Number(this.number),
        type: this.type,
        appKey: this.appKey,
        appEui: this.appEui,
        devEui: this.devEui
      };

      // Perform POST request to insert device
      axios
        .post("https://gowessel.teneo-iot.nl/insert/device", params)
        .then(res => {
          if (res.status == 200) {
            // TODO: Show success alert
            this.$router.push("/");
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            // Handle 401
            // TODO: Show error alert
            this.$router.push("/");
          }
        });
    },
    validate: function() {
      if (this.$refs.form.validate()) {
        this.insertDevice();
      }
    }
  }
};
</script>

<style scoped>
#secure {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 20px;
  margin-top: 10px;
}
</style>
