<template>
  <div id="overview">
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <v-list-item @click="toOverview()">
          <v-list-item-action>
            <v-icon>dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Overzicht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-icon>perm_identity</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Mijn account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="toDevicesAdd()">
          <v-list-item-action>
            <v-icon>add</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Device toevoegen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$session.get('admin') == 1" @click="toUsers()">
          <v-list-item-action>
            <v-icon>people</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Gebruikers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-icon>email</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Uitloggen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img
        src="@/assets/Logo-Teneo-500x200-transparant.png"
        alt="Teneo"
        max-height="100%"
        max-width="200px"
      ></v-img>
    </v-app-bar>

    <v-content>
      <v-container fluid fill-height>
        <v-layout
          align-center
          justify-center
          text-xs-center
          style="background-color: white; padding:10px;"
          class="elevation-3"
        >
          <div text-xs-center style="width:100%">
            <h3>Gegevens verwijderen</h3>
            <v-alert
              :value="unknownErrorAlert"
              type="error"
              transition="fade-transition"
              dismissible
              >Er deed zich een onverwachte fout voor. Probeer het later
              opnieuw.</v-alert>
            <v-alert
              :value="deletedAlert"
              type="success"
              transition="fade-transition"
              >Accountsgegevens zijn verwijderd. Klik <a @click="logout()">hier</a> om uit te loggen.</v-alert>
            <v-alert
              :value="networkErrorAlert"
              type="warning"
              transition="fade-transition"
              dismissible
              >Er deed zich een netwerkfout voor. Probeer het later opnieuw.</v-alert>
              <v-alert
              :value="alreadyDeletedAlert"
              type="warning"
              transition="fade-transition"
              dismissible
              >Uw account is reeds verwijderd. Klik <a @click="logout()">hier</a> om uit te loggen.</v-alert>
            <p>Door op onderstaande knop te klikken verwijdert u al uw bij ons opgeslagen persoonsgegevens.</p>
            <v-btn color="primary" @click="deleteAccount()">Verwijderen</v-btn>
          </div>
        </v-layout>
      </v-container>
    </v-content>

    <v-footer color="white" app>
      <span>&copy; Teneo IoT B.V. 2020</span>
    </v-footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      drawer: null,
      unknownErrorAlert: false,
      networkErrorAlert: false,
      deletedAlert: false,
      alreadyDeletedAlert: false
    };
  },
  beforeCreate: function() {
    if (!this.$session.exists() || this.$session.get("authToken") == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  methods: {
    toOverview: function() {
      this.$router.push("/overview");
    },
    toUsers: function() {
      this.$router.push("/users");
    },
    toDevicesAdd: function() {
      this.$router.push("/devices/add")
    },
    deleteAccount: function() {
      if(confirm("Weet u zeker dat u uw account wilt verwijderen?")) {
        const params = {
          token: this.$session.get("authToken")
        }

        axios.post("https://gowessel.teneo-iot.nl/delete/account", params)
        .then(res => {
          if (res.status == 200) {
            this.deletedAlert = true;
          }
        })
        .catch(error => {
          if (!error.response) {
            // Network error
            this.networkErrorAlert = true;
          } else if (error.response.status == 500) {
            // Handle 500 internal server error
            this.unknownErrorAlert = true;
          }
        })
      }
    },
    logout: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      };

      // Perform POST request to log out
      axios
        .post("https://gowessel.teneo-iot.nl/logout", params)
        .then(res => {
          if (res.status == 200) {
            this.networkErrorAlert = false;
            // Destroy session and reroute to main page
            this.$session.destroy();
            this.$router.push("/");
          }
        })
        .catch(error => {
          if (!error.response) {
            // Network error
            this.networkErrorAlert = true;
          } else if(error.status == 404) {
            // Handle 404 not found
            this.alreadyDeletedAlert = true;
          }
        })
    },
  }
};
</script>

<style scoped>
#secure {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 20px;
  margin-top: 10px;
}
</style>
