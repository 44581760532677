import Vue from "vue";
import VueRouter from "vue-router";
import LoginComponent from "../views/login.vue";
import OverviewComponent from "../views/overview.vue";
import InsertDeviceComponent from "../views/insertdevice.vue";
import UsersComponent from "../views/users.vue";
import ContactComponent from "../views/contact.vue";
import AccountComponent from "../views/account.vue";
import DeleteUserComponent from "../views/deleteuser.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {
      name: "login"
    }
  },
  {
    path: "/login",
    name: "login",
    component: LoginComponent
  },
  {
    path: "/overview",
    name: "overview",
    component: OverviewComponent
  },
  {
    path: "/devices/add",
    name: "adddevice",
    component: InsertDeviceComponent
  },
  {
    path: "/users",
    name: "users",
    component: UsersComponent
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactComponent
  },
  {
    path: "/account",
    name: "account",
    component: AccountComponent
  },
  {
    path: "/delete/user",
    name: "deleteuser",
    component: DeleteUserComponent
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(/* webpackChunkName: "about" */ "../views/About.vue");
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
