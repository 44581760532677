<template>
  <div id="overview">
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <v-list-item @click="toOverview()">
          <v-list-item-action>
            <v-icon>dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Overzicht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="toAccount()">
          <v-list-item-action>
            <v-icon>person</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Mijn account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-icon>people</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Gebruikers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="toContact()">
          <v-list-item-action>
            <v-icon>email</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Uitloggen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img
        src="@/assets/Logo-Teneo-500x200-transparant.png"
        alt="Teneo"
        max-height="100%"
        max-width="200px"
      ></v-img>
    </v-app-bar>

    <v-content>
      <v-dialog v-model="editDialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title
            >Gebruiker wijzigen</v-card-title
          >
          <v-card-text>
            <v-container>
              <v-row>
                <v-alert
                  :value="unknownErrorAlert"
                  type="error"
                  transition="fade-transition"
                  >Er deed zich een onverwachte fout voor. Probeer het later
                  opnieuw.</v-alert
                >
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedUser.username"
                    :rules="euUsernameRules"
                    label="Gebruikersnaam"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedUser.company"
                    :rules="euCompanyRules"
                    label="Bedrijf"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedUser.email"
                    :rules="euEmailRules"
                    label="Emailadres"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedUser.password"
                    :rules="euPasswordRules"
                    label="Wachtwoord"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="editedUser.admin"
                    :items="adminItems"
                    :rules="[v => !!v || 'Keuze is verplicht']"
                    label="Admin"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="processEditUser()"
              >Bijwerken</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-container fluid fill-height>
        <v-layout align-center justify-center text-xs-center>
          <div text-xs-center style="width:100%">
            <v-layout align-end justify-end>
              <v-btn
                color="primary"
                @click="insertUser()"
                dark
                class="mb-2"
                justify-right
                >Toevoegen</v-btn
              >
            </v-layout>
            <v-data-table
              :headers="headers"
              :items="users"
              multi-sort
              class="elevation-1"
            >
              <template
                v-slot:item.action="user"
                v-if="$session.get('admin') == 1"
              >
                <v-icon small class="mr-2" @click="editUser(user)">edit</v-icon>
                <v-icon small @click="deleteItem(user)">delete</v-icon>
              </template>
            </v-data-table>
          </div>
        </v-layout>
      </v-container>
    </v-content>

    <v-content>
      <v-dialog v-model="insertDialog" width="500">
        <v-form ref="insertForm" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title
              >Gebruiker toevoegen</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-alert
                    :value="unknownErrorAlert"
                    type="error"
                    transition="fade-transition"
                    >Er deed zich een onverwachte fout voor. Probeer het later
                    opnieuw.</v-alert
                  >
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="insertedUser.username"
                      :rules="iuUsernameRules"
                      label="Gebruikersnaam"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="insertedUser.company"
                      :rules="iuCompanyRules"
                      label="Bedrijf"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="insertedUser.email"
                      :rules="iuEmailRules"
                      label="Emailadres"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="insertedUser.password"
                      :rules="iuPasswordRules"
                      label="Wachtwoord"
                      type="password"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-model="insertedUser.admin"
                      :items="adminItems"
                      :rules="[v => !!v || 'Keuze is verplicht']"
                      label="Admin"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!valid" color="primary" text @click="validate()"
                >Toevoegen</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-content>

    <v-footer color="white" app>
      <span>&copy; Teneo IoT B.V. 2020</span>
    </v-footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Secure",
  data() {
    return {
      drawer: null,
      unknownErrorAlert: false,
      notFoundAlert: false,
      notAdminAlert: false,
      admin: false,
      valid: true,
      headers: [
        { text: "ID", value: "id", align: "left" },
        { text: "Gebruikersnaam", value: "username"},
        { text: "Bedrijf", value: "company" },
        { text: "Emailadres", value: "email" },
        { text: "Type", value: "admin" },
        { text: "Acties", value: "action", sortable: false }
      ],
      users: [],
      tempUsers: [],
      insertDialog: false,
      editDialog: false,
      item: null,
      insertedUser: {
        id: 0,
        username: null,
        company: null,
        email: null,
        password: null,
        admin: 0
      },
      editedUser: {
        id: 0,
        username: null,
        company: null,
        email: null,
        password: null,
        admin: 0
      },
      adminItems: ["Gebruiker", "Administrator"],
      iuUsernameRules: [value => !!value || "Gebruikersnaam is verplicht"],
      iuCompanyRules: [value => !!value || "Bedrijf is verplicht"],
      iuEmailRules: [
        value => !!value || "Emailadres is verplicht",
        value => /.+@.+/.test(value) || "Emailadres is ongeldig"
      ],
      iuPasswordRules: [
        value => !!value || "Wachtwoord is verplicht",
        value =>
          (value && value.length >= 5) ||
          "Wachtwoord moet uit ten minste 5 tekens bestaan"
      ],
      euUsernameRules: [value => !!value || "Gebruikersnaam is verplicht"],
      euCompanyRules: [value => !!value || "Bedrijf is verplicht"],
      euEmailRules: [
        value => !!value || "Emailadres is verplicht",
        value => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value) || "Emailadres is ongeldig"
      ],
      euPasswordRules: [
        value =>
          (value && value.length >= 5) ||
          "Wachtwoord moet uit ten minste 5 tekens bestaan"
      ]
    };
  },
  beforeCreate: function() {
    if (!this.$session.exists() || this.$session.get("authToken") == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  mounted: function() {
    // Get devices on initial page load
    this.$nextTick(this.getUsers());

    if (this.$session.get("admin") != 1) {
      // Remove userid & actions columns if user isn't admin
      this.headers.pop();
      //   this.headers.splice(-2, 2);
    }
  },
  methods: {
    toOverview: function() {
      this.$router.push("/overview");
    },
    toAccount: function() {
      this.$router.push("/account");
    },
    toContact: function() {
      this.$router.push("/contact");
    },
    processInsertUser: function() {
      // Check admin type
      if (this.insertedUser.admin == "Administrator") {
        this.insertedUser.admin = 1;
      } else {
        this.insertedUser.admin = 0;
      }

      // Post values to api
      axios
        .post("https://gowessel.teneo-iot.nl/insert/user", this.insertedUser)
        .then(res => {
          if (res.status == 200) {
            this.insertDialog = false;
          }
        })
        .catch(error => {
          if (error.response.status == 400) {
            this.unknownErrorAlert = true;
          } else if (error.response.status == 500) {
            this.unknownErrorAlert = true;
          }
        });
    },
    processEditUser: function() {
      // Check admin type
      if (this.editedUser.admin == "Administrator") {
        this.editedUser.admin = 1;
      } else {
        this.editedUser.admin = 0;
      }

      // Post values to api
      axios
        .post("https://gowessel.teneo-iot.nl/update/user", this.editedUser)
        .then(res => {
          if (res.status == 200) {
            // Reset editedUser value
            if (this.editedUser.admin == 1) {
              this.editedUser.admin = "Administrator";
            } else {
              this.editedUser.admin = "Gebruiker";
            }

            // Set editDialog to invisible
            this.editDialog = false;
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            // Handle 401
            this.unknownErrorAlert = true;
          }
        });

      // Set editDialog to invisible
      this.editDialog = false;
    },
    insertUser: function() {
      // Set insertDialog to visible
      this.insertDialog = true;
    },
    editUser: function(user) {
      // Set selected user data to the editDialog form
      this.editedUser = user.item;

      // Set editDialog to visible
      this.editDialog = true;
    },
    deleteItem: function(user) {
      if (confirm("Weet u zeker dat u deze gebruiker wilt verwijderen?")) {
        const params = {
          id: user.item.id
        };

        // Perform POST request to delete user
        axios
          .post("https://gowessel.teneo-iot.nl/delete/user", params)
          .then(res => {
            if (res.status == 200) {
              // Remove item from table
              this.getUsers();
            }
          })
          .catch(error => {
            if (error.status == 500) {
              // Handle 500
              this.unknownErrorAlert = true;
            }
          });
      }
    },
    logout: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      };

      // Perform POST request to log out
      axios
        .post("https://gowessel.teneo-iot.nl/logout", params)
        .then(res => {
          if (res.status == 200) {
            // Destroy session and reroute to main page
            this.$session.destroy();
            this.$router.push("/");
          }
        })
        .catch(error => {
          if (error.response.status == 404) {
            // Handle 404
            this.notFoundAlert = true;
          }
        });
    },
    getUsers: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      };

      // Execute POST request to retrieve users for user matching authToken
      axios
        .post("https://gowessel.teneo-iot.nl/retrieve/user/all", params)
        .then(res => {
          if (res.status == 200) {
            // Clean users table first
            this.users = [];

            // If the retrieval was successful
            // Set users variable to result data

            this.tempUsers = res.data;
            res.data.forEach(user => {
              if (user.admin == 1) {
                user.admin = "Administrator";
                this.users.push(user);
              } else {
                user.admin = "Gebruiker";
                this.users.push(user);
              }
            });
          }
        })
        .catch(error => {
          if (error.status == 403) {
            // Handle 403
            this.notAdminAlert = true;
          }
          if (error.status == 404) {
            // Handle 404
            this.notFoundAlert = true;
          }
        });
    },
    validate: function() {
      if (this.$refs.insertForm.validate()) {
        this.processInsertUser();
      }
    }
  }
};
</script>

<style scoped>
#secure {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 20px;
  margin-top: 10px;
}
</style>
