<template>
  <div id="overview">
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <v-list-item>
          <v-list-item-action>
            <v-icon>dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Overzicht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="toAccount()">
          <v-list-item-action>
            <v-icon>person</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Mijn account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$session.get('admin') == 1" @click="toUsers()">
          <v-list-item-action>
            <v-icon>people</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Gebruikers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="toContact()">
          <v-list-item-action>
            <v-icon>email</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Uitloggen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img
        src="@/assets/Logo-Teneo-500x200-transparant.png"
        alt="Teneo"
        max-height="100%"
        max-width="200px"
      ></v-img>
    </v-app-bar>

    <v-content>
      <v-container fluid>
        <v-layout align-center justify-center text-xs-center>
          <div text-xs-center style="width:100%">
            <v-layout align-end justify-end>
              <v-btn
                color="primary"
                @click="insertDevice()"
                v-if="$session.get('admin') == 1"
                dark
                class="mb-2"
                justify-right
                style="margin-right:10px;"
                ><v-icon>post_add</v-icon></v-btn
              >
              <v-btn
                color="primary"
                @click="insertDevices()"
                v-if="$session.get('admin') == 1"
                dark
                class="mb-2"
                justify-right
                style="margin-right:10px;"
                >
                  <v-icon>cloud_upload</v-icon>
                </v-btn
              >
              <v-btn
                color="success"
                dark
                class="mb-2"
                justify-right
                v-show="devices && devices.length"
                ><downloadexcel
                  class = "btn"
                  :fetch   = "fetchData"
                  :fields = "json_fields"
                  name    = "devices.xls"
                  type    = "xls">
                  <v-icon>cloud_download</v-icon>
                </downloadexcel></v-btn
              >
            </v-layout>
            <v-data-table
            id="datatable"
              :headers="headers"
              :items="devices"
              multi-sort
              class="elevation-1"
            >
              <template
                v-slot:item.action="device"
                v-if="$session.get('admin') == 1"
              >
                <v-icon small class="mr-2" @click="editDevice(device)"
                  >edit</v-icon
                >
                <v-icon small @click="deleteItem(device)">delete</v-icon>
              </template>
            </v-data-table>
          </div>
        </v-layout>
      </v-container>
      <v-dialog v-model="csvDialog" width="70%">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title
            >Devices importeren</v-card-title
          >
          <v-card-text>
            <v-container>
              <vue-csv-import v-model="csvKeys" :map-fields="['DeviceEUI', 'AppEUI', 'AppKey']" :headers="true" :autoMatchFields="true">
                <template slot="next" slot-scope="{load}">
                    <v-btn class="mb-2" color="success" style="margin-top: 5px" @click.prevent="load">Importeer</v-btn>
                </template>
              </vue-csv-import>
              <div v-if="csvKeys">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model.number="insertedDevices.number"
                      label="Beginnummer"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <div v-if="insertedDevices.type == 'anders...'">
                      <v-text-field 
                          autofocus
                          v-model="tempType" 
                          label="Type"
                          v-on:blur="handleTypeBlur">
                        </v-text-field>
                    </div>
                    <div v-else>
                      <v-select
                        v-model="insertedDevices.type"
                        :items="typeItems"
                        :rules="[v => !!v || 'Type is verplicht']"
                        label="Type"
                        required
                      ></v-select>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model.number="insertedDevices.userId"
                      label="Gebruiker ID"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-for="item in csvKeys" :key="item.DeviceEUI">
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="item.DeviceEUI"
                      label="Device EUI"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="item.AppEUI"
                      label="App EUI"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="item.AppKey"
                      label="App Key"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="processInsertDevices()"
              >Toevoegen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="editDialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title
            >Device wijzigen</v-card-title
          >

          <v-card-text>
            <v-container>
              <v-row>
                <v-alert
                  :value="unknownErrorAlert"
                  type="error"
                  transition="fade-transition"
                  >Er deed zich een onverwachte fout voor. Probeer het later
                  opnieuw.</v-alert
                >
                <v-alert
                  :value="notFoundAlert"
                  type="error"
                  transition="fade-transition"
                  >Kon niet uitloggen: account niet gevonden.</v-alert
                >
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model.number="editedDevice.number"
                    label="Nummer"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <div v-if="editedDevice.type == 'anders...'">
                    <v-text-field 
                        autofocus
                        v-model="tempType" 
                        label="Type"
                        v-on:blur="handleTypeBlur">
                      </v-text-field>
                  </div>
                  <div v-else>
                    <v-select
                      v-model="editedDevice.type"
                      :items="typeItems"
                      :rules="[v => !!v || 'Type is verplicht']"
                      label="Type"
                      required
                    ></v-select>
                  </div>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedDevice.type"
                    label="Type"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedDevice.appEui"
                    label="App EUI"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedDevice.appKey"
                    label="App Key"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedDevice.devEui"
                    label="Device EUI"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model.number="editedDevice.userId"
                    label="Gebruiker ID"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="processEditDevice()"
              >Bijwerken</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-content>
      <v-dialog v-model="insertDialog" width="500">
        <v-form ref="insertForm" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title
              >Device toevoegen</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-alert
                    :value="unknownErrorAlert"
                    type="error"
                    transition="fade-transition"
                    >Er deed zich een onverwachte fout voor. Probeer het later
                    opnieuw.</v-alert
                  >
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="insertedDevice.number"
                      :rules="idNumberRules"
                      label="Nummer"
                      type="number"
                      required
                    ><!-- Might not be required anymore?? -->
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <div v-if="insertedDevice.type == 'anders...'">
                      <v-text-field 
                          autofocus
                          v-model="tempType" 
                          label="Type"
                          v-on:blur="handleTypeBlur">
                        </v-text-field>
                    </div>
                    <div v-else>
                      <v-select
                        v-model="insertedDevice.type"
                        :items="typeItems"
                        :rules="[v => !!v || 'Type is verplicht']"
                        label="Type"
                        required
                      ></v-select>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="insertedDevice.appKey"
                      :rules="idAppKeyRules"
                      label="AppKey"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="insertedDevice.appEui"
                      :rules="idAppEuiRules"
                      label="AppEUI"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="insertedDevice.devEui"
                      :rules="idDevEUIRules"
                      label="DevEUI"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="insertedDevice.userId"
                      :rules="idUserIdRules"
                      label="UserID"
                      type="number"
                      required
                    ><!-- Change to username OR email --></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!valid" color="primary" text @click="validate()"
                >Toevoegen</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-content>

      
    </v-content>
    <v-footer color="white" app>
      <span>&copy; Teneo IoT B.V. 2020</span>
    </v-footer>
  </div>
</template>

<script>
import axios from "axios";
import downloadexcel from "vue-json-excel"
import VueCsvImport from "vue-csv-import"

export default {
  name: "Overview",
  components: {
    downloadexcel,
    VueCsvImport,
  },
  data() {
    return {
      csvKeys: null,
      drawer: null,
      unknownErrorAlert: false,
      notFoundAlert: false,
      admin: false,
      devices: [],
      insertDialog: false,
      editDialog: false,
      csvDialog: false,
      item: null,
      // typeItems: ["Grondvocht", "Vulgraad", "Watermeter", "TCM", "Universal GPS", "Anders"],
      typeItems: [],
      headers: [
        { text: "Nummer", align: "left", value: "number" },
        { text: "Type", value: "type" },
        { text: "AppKey", value: "appKey" },
        { text: "AppEUI", value: "appEui" },
        { text: "DevEUI", value: "devEui" },
        { text: "UserID", value: "userId" },
        { text: "Acties", value: "action", sortable: false }
      ],
      insertedDevice: {
        token: this.$session.get("authToken"),
        number: null,
        type: null,
        appKey: null,
        appEui: null,
        devEui: null
      },
      insertedDevices: {
        token: this.$session.get("authToken"),
        number: null,
        type: null,
        devices: null,
      },
      idNumberRules: [
        // value => !!value || "Nummer is verplicht",
        // value => (value && value > 0) || "Value moet groter dan 0 zijn"
      ],
      idAppKeyRules: [value => !!value || "AppKey is verplicht"],
      idAppEuiRules: [value => !!value || "AppEUI is verplicht"],
      idDevEUIRules: [value => !!value || "Device EUI is verplicht"],
      idUserIdRules: [
        value => !!value || "UserID is verplicht",
        value => (value && value > 0) || "UserID moet groter dan 0 zijn"
      ],
      editedDevice: {
        id: 0,
        number: 0,
        type: null,
        appEui: null,
        appKey: null,
        devEui: null,
        userId: 0
      },
      json_fields: {
        'Nummer': 'number',
        'Type': 'type',
        'AppKey': 'appKey',
        'AppEUI': 'appEui',
        'DevEUI': 'devEui',
      }
    };
  },
  beforeCreate: function() {
    if (!this.$session.exists() || this.$session.get("authToken") == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  mounted: function() {
    // Get devices on initial page load
    this.$nextTick(this.getDevices());
    this.getTypeItems();
    if (this.$session.get("admin") != 1) {
      // Remove userid & actions columns if user isn't admin
      this.headers.splice(-2, 2);
    } else {
      this.getTypeItems();
    }
  },
  methods: {
    toAccount: function() {
      this.$router.push("/account");
    },
    toDevicesAdd: function() {
      this.$router.push("/devices/add");
    },
    toUsers: function() {
      this.$router.push("/users");
    },
    toContact: function() {
      this.$router.push("/contact");
    },
    async fetchData() {
      const params = {
        token: this.$session.get("authToken")
      };
      
      // Post to retrieve devices, return data of said devices when done
      const res = await axios.post("https://gowessel.teneo-iot.nl/retrieve/device", params)
      return res.data;
    },
    processInsertDevice: function() {
      // Post values to api
      axios.post("https://gowessel.teneo-iot.nl/insert/device", this.insertedDevice)
      .then(res => {
        if (res.status == 200) {
          this.insertDialog = false;
        }
      })
      .catch(error => {
        if (error.response.status == 400) {
          this.unknownErrorAlert = true;
        } else if (error.response.status == 500) {
          this.unknownErrorAlert = true;
        }
      });
    },
    processInsertDevices: function() {
      // Post values to api
      this.insertedDevices.devices = this.csvKeys
      axios.post("https://gowessel.teneo-iot.nl/insert/devices", this.insertedDevices)
      .then(res => {
        if (res.status == 200) {
          this.csvDialog = false;
        }
      })
      .catch(error => {
        if (error.response.status == 400) {
          this.unknownErrorAlert = true;
        } else if (error.response.status == 500) {
          this.unknownErrorAlert = true;
        }
      });
    },
    processEditDevice: function() {
      // Post values to api
      axios
        .post("https://gowessel.teneo-iot.nl/update/device", this.editedDevice)
        .then(res => {
          if (res.status == 200) {
            // Set editDialog to invisible
            this.editDialog = false;
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            // Handle 401
            this.unknownErrorAlert = true;
          }
        });

      // Set editDialog to invisible
      this.editDialog = false;
    },
    insertDevice: function() {
      this.insertDialog = true;
    },
    insertDevices: function() {
      this.csvDialog = true;
    },
    editDevice: function(device) {
      // Set selected device data to the editDialog form
      this.editedDevice = device.item;

      // Set editDialog to visible
      this.editDialog = true;
    },
    deleteItem: function(device) {
      if (confirm("Weet u zeker dat u deze device wilt verwijderen?")) {
        const params = {
          id: device.item.id
        };

        // Perform POST request to delete device
        axios
          .post("https://gowessel.teneo-iot.nl/delete/device", params)
          .then(res => {
            if (res.status == 200) {
              // Remove item from table
              this.getDevices();
            }
          })
          .catch(error => {
            if (error.status == 500) {
              // Handle 500
              this.unknownErrorAlert = true;
            }
          });
      }
    },
    logout: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      };

      // Perform POST request to log out
      axios
        .post("https://gowessel.teneo-iot.nl/logout", params)
        .then(res => {
          if (res.status == 200) {
            // Destroy session and reroute to main page
            this.$session.destroy();
            this.$router.push("/");
          }
        })
        .catch(error => {
          if (error.response.status == 404) {
            // Handle 404
            this.notFoundAlert = true;
          }
        });
    },
    getDevices: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      };

      // Execute POST request to retrieve devices for user matching authToken
      axios
        .post("https://gowessel.teneo-iot.nl/retrieve/device", params)
        .then(res => {
          if (res.status == 200) {
            // If the retrieval was successful
            // Set devices variable to result data
            this.devices = res.data;
          }
        })
        .catch(error => {
          if (error.status == 404) {
            // Handle 404
            this.notFoundAlert = true;
          }
        });
    },
    getTypeItems: function() {
      // Specify parameters for request
      const params = {
        token: this.$session.get("authToken")
      };

      // Execute POST request to retrieve devices for user matching authToken
      axios
        .post("https://gowessel.teneo-iot.nl/retrieve/device/types", params)
        .then(res => {
          if (res.status == 200) {
            // If the retrieval was successful
            // Set devices variable to result data
            this.typeItems = res.data;
            this.typeItems.push("anders...")
          }
        })
        .catch(error => {
          if (error.status == 404) {
            // Handle 404
            this.notFoundAlert = true;
          }
        });
    },
    validate: function() {
      if (this.$refs.insertForm.validate()) {
        this.processInsertDevice();
      }
    },
    handleTypeBlur: function(e) {
      this.insertedDevice.type = e.target.value
      this.editedDevice.type = e.target.value
      this.insertedDevices.type = e.target.value
      this.typeItems.push(e.target.value)
    }
  }
};
</script>

<style scoped>
#secure {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 20px;
  margin-top: 10px;
}
</style>
