import Vue from "vue";
import App from "./App.vue";
// import Overview from "@/views/overview.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueSession from "vue-session";
import axios from "axios";
import JsonExcel from "vue-json-excel";
import {VueCsvImportPlugin} from "vue-csv-import";

Vue.use(VueSession);
Vue.component("downloadExcel", JsonExcel)

new Vue({
  el: "#app",
  template: "<App/>",
  components: { App, VueCsvImportPlugin },
  router,
  vuetify,
  axios,
  data: {
    json_fields: {
        'Complete name': 'name',
        'City': 'city',
        'Telephone': 'phone.mobile',
        'Telephone 2' : {
            field: 'phone.landline',
            callback: (value) => {
                return `Landline Phone - ${value}`;
            }
        },
    },
    json_data: [
        {
            'name': 'Tony Peña',
            'city': 'New York',
            'country': 'United States',
            'birthdate': '1978-03-15',
            'phone': {
                'mobile': '1-541-754-3010',
                'landline': '(541) 754-3010'
            }
        },
        {
            'name': 'Thessaloniki',
            'city': 'Athens',
            'country': 'Greece',
            'birthdate': '1987-11-23',
            'phone': {
                'mobile': '+1 855 275 5071',
                'landline': '(2741) 2621-244'
            }
        }
    ],
    json_meta: [
        [
            {
                'key': 'charset',
                'value': 'utf-8'
            }
        ]
    ],
  },

  render: function(h) {
    return h(App);
  }
}).$mount("#app");
